import React from 'react';
import Button from 'components/Buttons/Button';
import { ReactComponent as ArrowBack } from 'assets/icons/arrow-back.svg';
import PropTypes from 'prop-types';
import AppointmentInfo from '../Components/AppointmentInfo';

const PreviewInformation = ({ back, data, formData, next }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex items-center mb-[24px]">
          <Button theme="transparent" type="button" className="w-[77px] mr-[12px] h-8" onClick={back}>
            <>
              <ArrowBack fill="#748181" className="w-[7px] h-[12px] mr-[13.5px]" />
              Back
            </>
          </Button>
          <h2 className="text-16 text-neutral_black font-campton_m pl-[12px] border-l border-l-neutral_stroke_2">
            Preview Information
          </h2>
        </div>
        <AppointmentInfo data={data} type="booking" />
      </div>
      <div className="flex justify-end my-4">
        <Button
          name="Cancel"
          theme="transparent"
          type="button"
          onClick={back}
          className="h-[40px] text-16 w-[79px] mr-[16px]"
        />
        <Button
          name="Proceed to payment"
          theme="blue"
          type="button"
          onClick={next}
          className="h-[40px] text-16 w-[180px]"
          disabled={!formData?.providerId}
        />
      </div>
    </div>
  );
};

export default PreviewInformation;
PreviewInformation.propTypes = {
  back: PropTypes.func,
  formData: PropTypes.object,
  data: PropTypes.object,
  next: PropTypes.func,
};
