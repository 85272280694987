import React, { useEffect, useMemo } from 'react';
import DefaultDatepicker from 'components/Datepicker/Index';
import { userInitials } from 'utils/ImagePlaceholder';
import { ReactComponent as Calendar } from 'assets/svg/appointment/calendar.svg';
import Button from 'components/Buttons/Button';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar/Avatar';
import useAppointment from 'hooks/fetch/useAppointment';
import dayjs from 'dayjs';
import { useAppointmentState } from 'store/modules/appointments';
import Spinner from 'components/Spinner';
import { convertTo24HourFormat, isTimeInThePast } from 'utils/isTimeInRange';

const SelectTimeSlot = ({ formData, provider, setFormData, callback, next }) => {
  const { get_available_time_slot, loading } = useAppointment()
  const { availableTime } = useAppointmentState()

  const getEndTime = (timeStr) => {
    let [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    if (period === 'PM' && hours !== '12') {
      hours = String(Number(hours) + 12);
    } else if (period === 'AM' && hours === '12') {
      hours = '00';
    }

    let newHours = (Number(hours) + 1) % 24;

    let newPeriod = (newHours >= 12) ? 'PM' : 'AM';
    if (newHours > 12) {
      newHours -= 12;
    }

    return `${String(newHours).padStart(2, '0')}:${minutes} ${newPeriod}`;
  }

  useEffect(() => {
    setFormData({ ...formData, endTime: getEndTime(formData.startTime) })
  }, [formData.startTime])

  useEffect(() => {
    get_available_time_slot(`${formData?.providerId}/available-time`, { date: dayjs(formData?.appointmentDate).format('YYYY-MM-DD') })
  }, [formData?.appointmentDate])

  const checkIfTimeIsInThePast = (date, startT) => {
    const appointmentDate = date?.toISOString()
    const dateToCompare = dayjs(appointmentDate);

    const startDateIn24Hours = convertTo24HourFormat(startT).split(":")
    const start = dayjs(dateToCompare).set('hour', startDateIn24Hours[0]).set('minute', startDateIn24Hours[1]);
    const eS = dayjs(start)
    const endTime = new Date(eS.$d).setHours(eS.$d.getHours() + 0.59)

    return !!isTimeInThePast(appointmentDate, dayjs(start).format('hh:mm A'), dayjs(endTime).format('hh:mm A'))
  }

  const restructureAvailableTime = useMemo(() => {
    const appointmentDate = formData?.appointmentDate?.toISOString()
    const dateToCompare = dayjs(appointmentDate);
    const currentDate = dayjs();

    if (dateToCompare.isSame(currentDate, 'day')) {
      return availableTime?.map(el => {
        if (checkIfTimeIsInThePast(formData?.appointmentDate, el)) {
          return { time: el, status: true }
        }
        return { time: el, status: false }
      })
    }

    return availableTime?.map(el => {
      return { time: el, status: false }
    })
  }, [availableTime, formData?.appointmentDate])

  return (
    <div className="relative h-full flex flex-col justify-between">
      <div className='modal-body-right'>
        <div className="flex flex-col items-center bg-primary_tint_50 p-4 rounded-lg">
          <Avatar
            url={provider?.profilePic}
            width="32px"
            height="32px"
            textVariant="!text-24"
            alt={provider?.firstName?.split('')[0]}
            initials={userInitials(provider?.firstName, provider?.lastName)}
            className="bg-brand_secondary mr-1.5 w-16 h-16"
          />
          <div className="text-center mt-3">
            <p className="font-campton_m text-neutral_black capitalize">
              Dr. {provider?.firstName} {provider?.lastName}
            </p>
            <p className="font-campton_r text-neutral_body text-14">
              {provider.locationName} | {`${provider?.specialty ?? 'N/A'} , ${provider.qualification}`}
            </p>
          </div>
        </div>
        <div className="relative">
          <Calendar className='text-18 absolute z-10 left-0 cursor-pointer bottom-5' />
          <DefaultDatepicker
            label="Date"
            startDate={formData?.appointmentDate}
            minDate={new Date()}
            onSelect={(e) => setFormData({ ...formData, appointmentDate: e })}
            name="date"
            styles="border-none"
            showLabel={false}
            value={formData?.appointmentDate}
            showCalender={false}
            showTimeIcon={false}
            iconStyles="text-brand_primary text-14 font-campton_m"
            icon="(change)"
          />
        </div>
        {loading && <Spinner />}
        {!loading && restructureAvailableTime?.length > 0 && <div className="grid grid-cols-4 gap-2">
          {restructureAvailableTime?.map((item) => (
            <button
              className={`
              ${item?.status ? 'bg-neutral_body border-brand_blue text-neutral_white cursor-not-allowed' : 'text-neutral_body border-neutral_stroke_2 cursor-pointer'}
              ${formData.startTime === item?.time ? 'bg-[#F2F6FC] border-brand_blue text-brand_blue' : 'text-neutral_body border-neutral_stroke_2'}
              px-2 py-[7px] border  rounded-full items-center font-campton_r text-12 flex justify-center`}
              data-testid={`test-${item?.time}`}
              onClick={() => item?.status ? {} : setFormData({ ...formData, startTime: item?.time })}
              key={item?.time}
            >
              {item?.time}
            </button>
          ))}
        </div>
        }
        {!loading && !restructureAvailableTime?.length &&
          <p className='font-campton_m text-neutral_black text-center'>No Available Time, Kindly select another provider</p>
        }
      </div>
      <div className="modal-right-button-container">
        <Button
          name="Cancel"
          theme="transparent"
          type="button"
          onClick={callback}
          className="h-9 text-16 w-[79px] mr-[16px]"
        />
        <Button
          name="Continue"
          disabled={!formData?.startTime}
          theme="blue"
          className="w-[95px] h-9 cursor-pointer"
          onClick={next}
        />
      </div>
    </div>
  );
};

export default SelectTimeSlot;
SelectTimeSlot.propTypes = {
  formData: PropTypes.object,
  provider: PropTypes.object,
  setFormData: PropTypes.func,
  next: PropTypes.func,
  callback: PropTypes.func,
};
